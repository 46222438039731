import React, { useState, useEffect, useRef } from 'react';
import { Badge, Checkbox, Result, Spin, Upload, message, Layout, Steps, Menu, PageHeader, Row, Col, Form, Input, Button, Divider, Select, Radio, DatePicker, TimePicker, Modal } from 'antd';
import { InboxOutlined, UploadOutlined, CrownOutlined, EditOutlined, FileZipOutlined, CheckCircleOutlined, LinkOutlined } from '@ant-design/icons';
import { enquireScreen } from 'enquire-js';
import CryptoJS from 'crypto-js';
import moment from 'moment';
import 'antd/dist/antd.css';
import './App.css';
import logo from './logo.svg';
const { Step } = Steps;
const { Dragger } = Upload;
const { Header, Content, Footer } = Layout;
const axios = require('axios').default;

let _isMobile;
enquireScreen((b) => {
  _isMobile = b;
});
function App(props) {
  const [isMobile, setIsMobile] = useState(_isMobile);
  const [formData, setFormData] = useState();
  const [data, setData] = useState({});
  const [current, setCurrent] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [form] = Form.useForm();
  const uploadUrlRef = useRef();
  const formRef = useRef({});
  const inputRef = useRef('');
  const [modalVisible, setModalVisible] = useState(false);
  useEffect(() => {
    enquireScreen((b) => {
      setIsMobile(!!b);
    });
    axios.get("/api/homework/info?key=" + window.location.href.split('/')[3]).then((res) => {
      setFormData(res.data);
      if (!res.data.success) {
        message.error(res.data.errorMessage);
        console.error(res.data.errorMessage);
      }
    }).catch((err) => {
      console.error(err);
      message.error(err.message);
      setFormData(false);
    });
  }, [props.location]);

  return (
    <Layout>
      <Header className="header">
        <div className="logo">
          <Badge count={"BETA"} color="#00bfff" size={"small"} style={{ top: "12px", left: "15px", right: "-20px" }} >
            <img src={logo} alt="logo" width="100%" />
          </Badge>
        </div>
        <Menu selectedKeys={["home"]} mode="horizontal" theme="light" >
          <Menu.Item key="home" icon={<UploadOutlined />}>
            {(window.location.href.split('/')[3] === '16' || window.location.href.split('/')[3] === '17') ?
              "文件上传" :
              window.location.href.split('/')[3] === '19' ? "天梯赛报名" :
                "作业提交"
            }
          </Menu.Item>
          <Menu.Item key="blog" icon={<LinkOutlined />}>
            <a href="https://xjzsq.cn" target="_blank" rel="noopener noreferrer">
              青い記憶
            </a>
          </Menu.Item>
          <Menu.Item key="admin" icon={<CrownOutlined />}>
            <a href="/admin/" rel="noopener noreferrer">
              管理后台
            </a>
          </Menu.Item>
        </Menu>
      </Header>
      <Content>
        <Modal
          title="敏感信息解密"
          visible={modalVisible}
          onOk={() => {
            let data = { ...formRef.current };
            let secret = inputRef.current;
            if (secret.length !== 6) {
              message.error("身份证后六位不正确");
              return;
            }
            for (let x in data) {
              try {
                data[x] = CryptoJS.AES.decrypt(data[x], secret).toString(CryptoJS.enc.Utf8);
                if (data[x] == '') {
                  message.error("身份证后六位不正确");
                  return;
                }
              } catch (e) {
                console.error(e);
                message.error("身份证后六位不正确");
                return;
              }
            }
            form.setFieldsValue(data);
            message.success("敏感信息解密成功，已自动填充");
            setModalVisible(false);
          }}
          okText="解密"
          onCancel={() => { setModalVisible(false); }}
          cancelText="自行填写敏感数据"
          maskClosable={false}
          closable={false}
        >
          敏感信息（邮箱、身份证、手机）已经通过AES算法进行加密，请先输入身份证后六位（X字母请大写）进行解密：
          <Input
            placeholder="身份证后六位"
            style={{ marginTop: "10px" }}
            onChange={(e) => {
              inputRef.current = e.target.value;
            }
            } />
        </Modal>
        {formData ? formData.success ?
          <Row>
            <Col span={18} offset={3}>
              <PageHeader title={formData.data.homeworkName} subTitle={`截止时间：${moment(formData.data.deadline).format('YYYY-MM-DD HH:mm:ss')}`}>
                <Steps direction={isMobile ? "vertical" : "horizontal"} >
                  {formData.data.steps.map((step, index) =>
                    <Step
                      key={'step-' + index}
                      status={current > index ? 'finish' : current < index ? 'wait' : 'process'}
                      title={step.title}
                      icon={step.type === 'info' ?
                        <EditOutlined /> :
                        <FileZipOutlined />}>
                    </Step>
                  )}
                  <Step
                    key={'step-' + formData.data.steps.length}
                    status={current < formData.data.steps.length ? 'wait' : 'process'}
                    title={'完成~'}
                    icon={<CheckCircleOutlined />}>
                  </Step>
                </Steps>
              </PageHeader>
              <Divider />
              {formData.data.steps.map((step, index) => {
                if (current === index) {
                  if (step.type === 'info') {
                    return (
                      <Form
                        key={'form-' + index}
                        style={{ paddingTop: isMobile ? 0 : '30px' }}
                        labelCol={{ span: isMobile ? 24 : 8 }}
                        wrapperCol={{ span: isMobile ? 24 : 8 }}
                        name={index}
                        form={form}
                        onFinish={
                          (values) => {
                            let remember = values['remember'];
                            delete values['remember'];
                            axios.post("/api/homework/submit", { id: formData.id, key: window.location.href.split('/')[3], current, data: { data: values } }).then((res) => {
                              if (remember) {
                                for (let key in values) {
                                  localStorage.setItem(key, values[key]);
                                }
                              }
                              setData(data => ({ ...data, ...values }));
                              if (values['身份'] === '校队成员' || values['身份'] === 'ACM班') {
                                setCurrent(current + 2);
                              } else {
                                if (window.location.href.split('/')[3] === '19' && current === 0) {
                                  let _data = {
                                    knownData: {
                                      '姓名': values['姓名'],
                                      '学号': values['学号'],
                                    },
                                    queryData: ['身份', '性别', '专业（完整）', '电话', '身份证号码', '邮箱']
                                  };
                                  axios.get("/api/data/get?key=19&data=" + JSON.stringify(_data)).then((res) => {
                                    if (res.data.success) {
                                      if (res.data.data) {
                                        for (let x in res.data.data) {
                                          if (x === '身份证号码' || x === '邮箱' || x === '电话') {
                                            formRef.current[x] = res.data.data[x];
                                          } else {
                                            form.setFieldsValue({ [x]: res.data.data[x] });
                                          }
                                        }
                                        if (res.data.data['身份证号码'] && res.data.data['邮箱'] && res.data.data['电话'])
                                          setModalVisible(true);
                                        message.success("已自动填充非敏感信息");
                                      }
                                    } else {
                                      message.error(res.data.errorMessage);
                                    }
                                  }).catch((err) => {
                                    console.error(err);
                                    message.error(err.message);
                                  });
                                }
                                setCurrent(current + 1);
                              }
                            }).catch((err) => {
                              console.error(err);
                              message.error("提交失败，请检查网络后重试，错误信息：", err.message);
                            });
                          }
                        }
                      >
                        {step.info.map((info) => {
                          let rules = {
                            message: '请输入正确的' + info.name + '...',
                          };
                          if (info.rules) {
                            if (info.rules.required) rules.required = true;
                            if (info.rules.pattern) rules.pattern = eval(info.rules.pattern);
                            if (info.rules.type) rules.type = info.rules.type;
                          }
                          if (info.rules === undefined || info.rules.required === undefined) rules.required = true;
                          return <Form.Item
                            labelCol={{ span: isMobile ? 24 : 9 }}
                            wrapperCol={{ span: isMobile ? 24 : 6 }}
                            key={'form-' + index + '-info-' + info.name}
                            label={info.name}
                            name={info.name}
                            tooltip={info.tooltip || false}
                            rules={[rules]}
                            initialValue={localStorage.getItem(info.name)}
                          >
                            {
                              info.type === 'textarea' ?
                                <Input.TextArea /> :
                                info.type === 'select' ?
                                  <Select>
                                    {info.options.map((option) =>
                                      <Select.Option key={option} value={option}>{option}</Select.Option>
                                    )}
                                  </Select> :
                                  info.type === 'radio' ?
                                    <Radio.Group>
                                      {info.options.map((option) =>
                                        <Radio key={option} value={option}>{option}</Radio>
                                      )}
                                    </Radio.Group> :
                                    info.type === 'checkbox' ?
                                      <Checkbox.Group>
                                        {info.options.map((option) =>
                                          <Checkbox key={option} value={option}>{option}</Checkbox>
                                        )}
                                      </Checkbox.Group> :
                                      info.type === 'date' ?
                                        <DatePicker /> :
                                        info.type === 'time' ?
                                          <TimePicker /> :
                                          info.type === 'datetime' ?
                                            <DatePicker showTime /> :
                                            <Input />
                            }
                          </Form.Item>
                        })}
                        {window.location.href.split('/')[3] !== '19' || current !== 1 &&
                          <Form.Item {...tailLayout} name="remember" valuePropName="checked" key={'form-' + index + '-remember'} initialValue={true}>
                            <Checkbox>记住信息</Checkbox>
                          </Form.Item>
                        }
                        <Form.Item key={'form-' + index + '-info-next'} {...tailLayout}>
                          <Button type="primary" htmlType="submit" >
                            下一步
                          </Button>
                        </Form.Item>
                      </Form>
                    );
                  } else if (step.type === 'upload') {
                    return (
                      <Dragger
                        key={'dragger-' + index}
                        name={step.name}
                        disabled={uploading}
                        action={() => {
                          return uploadUrlRef.current;
                        }}
                        method="PUT"
                        multiple={false}
                        beforeUpload={
                          async file => {
                            let ext = file.name.substring(file.name.lastIndexOf('.')).toLowerCase();
                            if (step.ext.indexOf(ext) === -1) {
                              message.error(`${file.name} 不是一个 ${step.ext.join('/')} 文件！`, 3);
                              return Upload.LIST_IGNORE;
                            }
                            let name = step.filename.map((item) => data[item.content] || item.content).join('');
                            const p = new Promise((resolve, reject) => {
                              axios.post('/api/homework/submit', { id: formData.id, key: window.location.href.split('/')[3], current, data: { name: step.title + '/' + name + ext } }).then((res) => {
                                uploadUrlRef.current = res.data.url;
                                setUploading(true);
                                resolve(file);
                              }).catch((err) => {
                                message.error(err.message);
                                console.error(err);
                                reject(err);
                              });
                            });
                            return p;
                          }
                        }
                        customRequest={
                          (e) => {
                            const { action, file, onError, onProgress, onSuccess } = e
                            axios.put(action, file, {
                              headers: { 'Content-Type': file.type },
                              onUploadProgress: (ev) => {
                                const percent = ((ev.loaded / ev.total) * 100) | 0;
                                onProgress({ percent }, file);
                              },
                            }).then(() => {
                              onSuccess(file);
                            },
                              (res) => {
                                onError(res);
                              },
                            );
                          }
                        }
                        onChange={
                          (info) => {
                            const { status } = info.file;
                            if (status === 'done') {
                              setCurrent(current + 1);
                              setUploading(false);
                            }
                          }
                        }
                      >
                        <p className="ant-upload-drag-icon">
                          <InboxOutlined />
                        </p>
                        <p className="ant-upload-text">
                          {step.text}
                        </p>
                        <p className="ant-upload-hint">
                          {step.hint}
                        </p>
                      </Dragger>
                    );
                  }
                  return <Button type='primary' onClick={() => { setCurrent(current + 1) }}>下一步</Button>;
                }
                return <></>
              })}
              {current === formData.data.steps.length && (
                <Result
                  status="success"
                  title={(window.location.href.split('/')[3] === 16 || window.location.href.split('/')[3] === 17) ?
                    "提交成功！请在" + (parseInt(data['学号'].substr(data['学号'].length - 1)) < 3 ? "10:00~12:00" : parseInt(data['学号'].substr(data['学号'].length - 1)) < 7 ? "14:00~16:30" : "16:30~19:00") + "参加线上批卷。" : "提交成功！"}
                  subTitle={(window.location.href.split('/')[3] === 16 || window.location.href.split('/')[3] === 17) ?
                    "如需重新提交请刷新页面填写相同姓名学号提交，系统将会保留最新的一份文件。" : "如需重新提交请刷新页面重新提交"}
                  size="large"
                />
              )}
            </Col>
          </Row>
          :
          <Result
            status={formData.errorStatus || "404"}
            title={formData.errorCode || "404"}
            subTitle={formData.errorMessage || "未知错误"}
          />
          :
          formData === undefined ?
            <div className="loading">
              <Spin />
            </div>
            :
            <Result
              status="500"
              title="500"
              subTitle="服务器错误，请联系管理员（或许你可以试试刷新一下）"
            />
        }
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        作业提交系统  @2022 Crafted with ❤ by <a href="https://xjzsq.cn" target="_blank" rel="noreferrer">xjzsq</a>{(window.location.href.split('/')[3] === '16' || window.location.href.split('/')[3] === '17') ? "@SAST" : window.location.href.split('/')[3] === '19' ? "@南邮ICPC校队" : ""},
        Powered by <a href="https://reactjs.org/" target="_blank" rel="noreferrer"> React </a >
      </Footer >
    </Layout >
  );
}

export default App;
const tailLayout = {
  wrapperCol: {
    offset: 9,
    span: 16,
  },
};
